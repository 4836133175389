import './actionButton.css';
import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { ButtonHTMLAttributes} from 'react';

function ActionButton(props:BaseButtonProps&ButtonHTMLAttributes<HTMLElement>&{onClick?:()=>void}) {
    return (
        <Button {...props} className={`${props.className} actionButton`}>{props.children}</Button>      
    );
}

export default ActionButton;