import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Login from './pages/login/Login';
import {AppThemeProvider} from './components/ThemeProviders';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Protected from './components/Protected';
import Dashboard from './pages/dashboard/Dashboard';
import CreateText from './pages/createText/CreateText';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/app/dashboard"/>
  },
  {
    path: "/app",
    element: <Protected><App /></Protected>,
    children: [
      {
        path: "dashboard",
        element: <Protected><Dashboard /></Protected>
      },
      // {
      //   path: "newContent",
      //   element: <Protected><NewText /></Protected>
      // },
      {
        path: "createContent",
        element: <Protected><CreateText /></Protected>
      },
    ]
  },
  {
    path: "login",
    element: <Login/>
  }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AppThemeProvider>
      <RouterProvider router={router} />
    </AppThemeProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
