import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

export default function Protected(props:{ children:ReactNode }) {

  const token = sessionStorage.getItem("idToken")
  if (!token) {
    // user is not authenticated
    return (<Navigate to="/login" />)
  }
  return (<>{props.children}</>);
};