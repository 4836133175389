import React, { useEffect, useState } from "react";
import { ContentState, Editor as DraftEditor, EditorState, RichUtils, convertFromHTML } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './journoeditor.css';
import { stateToHTML } from 'draft-js-export-html';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Queries from "../../functions/Queries";
import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined } from "@ant-design/icons";
import { Alert, Button, Flex, Input, Spin, Tag } from "antd";

export function Editor(props:{
  id?: string,
  type?: string
  title?:string,
  text?: string,
  onRegenerate?: () => void,
  canGenerate?: boolean
}){

const [title, setTitle] = useState<string>('')
const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
const [changed, setChanged] = useState<boolean>(false)
const [editorRef, setEditorRef] = useState<any | null>(null);
const [editorCopyWarning, setEditorCopyWarning] = useState(false)
const [awaitSaveVersion, setAwaitSaveVersion] = useState(false)
const queryClient = useQueryClient();
const [selectedStyles,setSelectedStyles] = useState<string[]>([])

const saveVersionMutation = useMutation(Queries.mutations.UPDATE_TEXT_CONTENT);

useEffect(() => {
  if (props.title) setTitle(props.title)
  if (props.text){
    const arrayText = props.text!.split("\n").map(x=>`<p>${x}</p>`)
    const blocksFromHTML = convertFromHTML(arrayText.join(""));
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks,blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(state))
  }
  
}, [props])

useEffect(() => {
  if (editorRef) {
    (editorRef.editor as any).addEventListener('copy', () => {
      setEditorCopyWarning(true)
    })
  }
}, [editorRef])

const blockStyleFn = (block: any) => {
  return 'journo-editor-block';
}

const blockRendered = (block: any) => {
  // env.log.info('editorrr',block)
}

const toggleStyle = (style: string,checked:boolean) => {
  const nextSelectedTags = checked
      ? [...selectedStyles, style]
      : selectedStyles.filter((t) => t !== style);
    setSelectedStyles(nextSelectedTags);
  handleTextChange(RichUtils.toggleInlineStyle(editorState, style))
}

const copyText = async () => {
  const html = stateToHTML(editorState.getCurrentContent());
  await navigator.clipboard.write([
    new ClipboardItem({
      "text/html": new Blob([html], { type: "text/html" }),
    })
  ]);
}

const copyTitle = async () => {
  await navigator.clipboard.write([
    new ClipboardItem({
      "text/plain": new Blob([title], { type: "text/plain" }),
    })
  ]);
}

const stateChanged = (a: EditorState, b: EditorState) => {
  const aContent = a.getCurrentContent();
  const bContent = b.getCurrentContent();
  const contentChanged = !aContent.equals(bContent)

  const aStyle = a.getCurrentInlineStyle();
  const bStyle = b.getCurrentInlineStyle();
  const styleChanged = !aStyle.equals(bStyle)

  return contentChanged || styleChanged
}

const handleTextChange = (state: EditorState) => {
  const stateChange = stateChanged(state, editorState)

  if (stateChange)
    setChanged(true)
  setEditorState(state)
}

const save = () => {
  setAwaitSaveVersion(true)
  saveVersionMutation.mutate({
    content:stateToHTML(editorState.getCurrentContent()),
    title: title,
    type: props.type!,
    id: props.id!
  }, {
    onSuccess: (data: boolean) => {
      setAwaitSaveVersion(false);
    },
    onError: (err) => {
      console.error(err)
      setAwaitSaveVersion(false);
    }
  });
}

var styleButtons = [
  {
    label: 'BOLD',
    icon: (<BoldOutlined />),
    tooltip: 'Negrito'
  },
  {
    label: 'ITALIC',
    icon: (<ItalicOutlined />),
    tooltip: 'Itálico'
  },
  {
    label: 'UNDERLINED',
    icon: (<UnderlineOutlined />),
    tooltip: 'Sublinhado'
  },
  {
    label: 'STRIKETHROUGH',
    icon: (<StrikethroughOutlined />),
    tooltip: 'Riscado'
  },
]

return (
  <Flex vertical gap="middle" className="editor-container">
    <Flex justify="center" gap="large">
        {
          styleButtons.map(x => (
            <Tag.CheckableTag key={x.label} checked={selectedStyles.indexOf(x.label) !== -1} onChange={(checked)=>toggleStyle(x.label,checked)} className="editor-tag">{x.icon}</Tag.CheckableTag>
          ))
        }
      <Button onClick={copyText}>Copiar Texto</Button>
      <Button onClick={props.onRegenerate} disabled={!(!!props.canGenerate)}>{editorState.getCurrentContent().hasText() ? "Regerar texto": "Gerar texto"} </Button>
      <Button disabled={!changed || awaitSaveVersion || (!props.id)} onClick={save}>Salvar Alterações</Button>
      </Flex>
      {editorCopyWarning && (<Alert type="warning" closable afterClose={() => { setEditorCopyWarning(false) }} message="Copiar o texto com CTRL+C não copia a formatação. Para copiar o texto formatado, utilize o botão COPIAR TEXTO"/>)}
    <div className="editor-text-container">
      <DraftEditor editorState={editorState} onChange={handleTextChange} blockStyleFn={blockStyleFn} blockRendererFn={blockRendered} ref={setEditorRef} readOnly={!editorState.getCurrentContent().hasText() }/>
    </div>
    <Spin spinning={awaitSaveVersion} percent="auto" fullscreen />
  </Flex>
);
}

export default Editor;
