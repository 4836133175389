import { QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import React from 'react';

interface QuestionPopupProps {
    text: string;
    onClick?: () => void;
}

const QuestionPopup: React.FC<QuestionPopupProps> = (props:QuestionPopupProps) => {
    const content = (
        <Flex vertical>
            {props.text.split('##').map((line, index) => (<span key={index}>{line}</span>))}
            {props.onClick && <span>Clique para saber mais</span>}
        </Flex>
    )
    return (
        <Tooltip title={content}>
            <QuestionCircleOutlined style={(props.onClick ? {cursor:'pointer'} : undefined)} onClick={props.onClick}/>
        </Tooltip>    );
};

export default QuestionPopup;