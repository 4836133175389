import { Collapse, Modal } from "antd";

export interface PromptDialogProps {
    open: boolean;
    onClose: () => void;
    prompts: { role: string, content: string }[]
}

export function PromptDialog(props: PromptDialogProps) {

    const handleClose = () => {
        props.onClose();
    };

    if(!props.prompts) {
        return null;
    }

    const collapseItems = props.prompts.map((prompt, idx) => {
      
        const children = (
            <div style={{width: '100%'}}>
                {prompt.content.split('\n').map((el, idx) => (
                    <p style={{marginBottom: '1.5em'}} key={idx}>
                        {el}
                    </p>
                ))}
            </div>            
        )

        return {key: idx, label: prompt.role, children}
    });

    return (
        <Modal onCancel={handleClose} open={props.open} title="Prompts" footer={<></>}>
            <Collapse items={collapseItems} />
        </Modal>
    );
}