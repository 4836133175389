import * as React from 'react';
import ActionButton from '../../components/ActionButton';
import { Button, Descriptions, Flex, Input } from 'antd';
import { TESTIMONY } from '../../functions/Queries';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { set } from 'date-fns';


type TestimonyData = TESTIMONY & {
  editing: boolean;
  isNew: boolean;
};

export default function Testimony(props: {
  onDataChanged: (val: TESTIMONY[]) => void,
  data?: TESTIMONY[]
}) {

  const [testimonies, setTestimonies] = React.useState<TestimonyData[]>((props.data || []).map(testimony => ({ ...testimony, editing: false, isNew: false })))

  const onDelete = (idx: number) => {
    testimonies.splice(idx, 1)
    setTestimonies([...testimonies])
    props.onDataChanged(testimonies)
  }

  const onDataChanged = (val: TESTIMONY,idx:number) => {
    testimonies[idx] = {...val,editing: false, isNew: false}
    setTestimonies([...testimonies])
    props.onDataChanged(testimonies)
  }

  const onAdd = () => {
    setTestimonies([...testimonies,{ name: '', position: '', statement: '', editing: true, isNew: true }])
  }


  return (
    <Flex vertical align='center' gap='middle'>
      <div>
        <ActionButton onClick={onAdd}>NOVO DEPOIMENTO</ActionButton>
      </div>
      {testimonies.map((e, idx) => (<TestimonyItem key={idx} testimony={e} idx={idx} onDelete={onDelete} onDataChanged={onDataChanged}/>))}
    </Flex>
  )

}

function TestimonyItem(props: {
  testimony: TestimonyData,
  idx: number,
  onDelete: (idx: number) => void
  onDataChanged: (val: TESTIMONY,idx:number) => void
}) {
  const { testimony, idx, onDelete } = props
  const [name, setName] = React.useState(testimony.name)
  const [position, setPosition] = React.useState(testimony.position)
  const [statement, setStatement] = React.useState(testimony.statement)
  const [isNameValid, setIsNameValid] = React.useState(false)
  const [isPositionValid, setIsPositionValid] = React.useState(false) 
  const [isStatementValid, setIsStatementValid] = React.useState(false)
  const [canSave, setCanSave] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(testimony.editing)

  React.useEffect(() => {
    if (testimony.isNew) {
      setIsNameValid(name.length > 0 && name !== testimony.name)
    }
  }, [name])

  React.useEffect(() => {
    if (testimony.isNew) {
      setIsPositionValid(position.length > 0 && position !== testimony.position)
    }
  }, [position])

  React.useEffect(() => {
    if (testimony.isNew) {
      setIsStatementValid(statement.length > 0 && statement !== testimony.statement)
    }
  }, [statement])

  React.useEffect(() => {
    const canSaveName = name.length > 0 && name !== testimony.name
    const canSavePosition = position.length > 0 && position !== testimony.position
    const canSaveStatement = statement.length > 0 && statement !== testimony.statement
    setCanSave(canSaveName && canSavePosition && canSaveStatement)
  }, [name,position,statement])

  const onEdit = () => {
    setName(testimony.name)
    setPosition(testimony.position)
    setStatement(testimony.statement)
    setIsEditing(true)
  }

  const onCancel = () => {
    if(testimony.isNew) {
      onDelete(idx)
    } else {
      setName(testimony.name)
      setPosition(testimony.position)
      setStatement(testimony.statement)
    }
    setIsEditing(false)
  }

  const onConfirmEdit = () => {
    setIsEditing(false)
    props.onDataChanged({name,position,statement},idx)
  }

  var title = (
    <Flex gap={4}>
      {testimony.name}
      <Button shape='circle' icon={<EditOutlined />} size="small" onClick={onEdit}  />
      <Button shape='circle' size="small" icon={<DeleteOutlined />} onClick={() => onDelete(props.idx)} />
    </Flex>
  )

  var positionControl:React.ReactElement | string = testimony.position
  var statementControl:React.ReactElement | string = testimony.statement

  if (isEditing) {
    title = (
      <Flex gap={4}>
        <Input value={name} onChange={(e) => setName(e.target.value)} status={isNameValid ? undefined : "error"} placeholder='Insira Nome'/>
        <Button shape='circle' icon={<CheckOutlined />} size="small" onClick={onConfirmEdit} disabled={!canSave}/>
        <Button shape='circle' size="small" icon={<CloseOutlined />} onClick={onCancel} />
      </Flex>
    )
    positionControl = (
      <Input value={position} onChange={(e) => setPosition(e.target.value)} status={isPositionValid? undefined : "error"} placeholder='Insira Cargo'  />
    )
    statementControl = (
      <TextArea value={statement} rows={4} onChange={(e) => setStatement(e.target.value)} status={isStatementValid? undefined : "error"} placeholder='Insira Depoimento'/>
    )
  }

    return (
      <Descriptions title={title} bordered layout='vertical' className='create-text-testimony-card'>
        <Descriptions.Item className='position-testimony-card'  label="Cargo">{positionControl}</Descriptions.Item>
        <Descriptions.Item label="Depoimento">{statementControl}</Descriptions.Item>
      </Descriptions>
    )
}

