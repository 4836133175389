import { Post,Email, Newspaper, EmailMKT } from '../components/icons';
import Mural from '../components/icons/Mural';
import TV from '../components/icons/TV';

export type TextType = {
  icon: JSX.Element,
  title: string,
  shortTitle: string,
  description: string,
  params: {
    type: string
  }
}

export const TextTypes:TextType[] = [
    {
      icon: (<Newspaper />),
      title: 'Matéria Newsletter',
      shortTitle: 'Matéria',
      description: 'Textos em geral entre 1.000 e 2.000 caracteres a serem veiculados em newsletters, intranets, blogs internos, e-mails em formatos de notícia, jornais e revistas internas e outros de formato similar.',
      params: {type:'newsletter'}
    },
    {
      icon: (<Email />),
      title: 'Comunicado por e-mail',
      shortTitle: 'E-Mail',
      description: 'Textos em geral entre 500 e 1.500 caracteres que tratam assuntos informativos, como mudança na empresa, avisos e outros temas de características similares, a serem enviados por e-mail.',
      params: {type:'email'}
    },
    {
      icon: (<EmailMKT />),
      title: 'E-mail Marketing',
      shortTitle: 'E-mail mkt',
      description: 'Textos preferencialmente até 800 caracteres sobre assuntos mais promocionais, como campanhas, notícias positivas ou ações que demandam engajamento, a serem enviados por e-mail, mural impresso ou TV (notícias de várias telas).',
      params: {type:'emailMKT'}
    },
    {
      icon: (<TV />),
      title: 'TV',
      shortTitle: 'TV',
      description: 'Textos preferencialmente até 800 caracteres sobre assuntos mais promocionais, como campanhas, notícias positivas ou ações que demandam engajamento, a serem enviados por e-mail, mural impresso ou TV (notícias de várias telas).',
      params: {type:'tv'}
    },
    {
      icon: (<Mural />),
      title: 'Mural',
      shortTitle: 'Mural',
      description: 'Textos preferencialmente até 800 caracteres sobre assuntos mais promocionais, como campanhas, notícias positivas ou ações que demandam engajamento, a serem enviados por e-mail, mural impresso ou TV (notícias de várias telas).',
      params: {type:'mural'}
    },
    {
      icon: (<Post />),
      title: 'Post para aplicativo ou rede social corporativa',
      shortTitle: 'Post ou Card',
      description: 'Textos preferencialmente até 800 caracteres sobre assuntos diversos, utilizando linguagem de posts de redes sociais a serem veiculados em aplicativos de mensagens ou redes sociais corporativas.',
      params: {type:'post'}
    },
  ]

export function GetTextType(type:string):TextType {
  const value = type === 'intranet' ? 'newsletter' : type;

  const retVal = TextTypes.find(el => el.params?.type === value) || ({
    icon: <Newspaper/>,
    shortTitle: type,
    title: '',
    description: '',
    params : {}
  } as TextType)
  return retVal;
}