import { Alert, Button, Flex, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./login.css";
import { doConfirmPasswordReset, doResetPassword } from "../../functions/CognitoClient";
import { InfoCircleFilled } from "@ant-design/icons";

export interface ResetPasswordDialogProps {
  open: boolean;
  userName: string;
  onClose: ()=>void
}


export function ResetPasswordDialog(props: ResetPasswordDialogProps) {

  const [emailSent, setEmailSent] = useState<string>()
  const [step, setStep] = useState(0);
  const [password,setPassword] = useState('')
  const [confirm,setConfirm] = useState('')
  const [code,setCode] = useState('')
  const [resetEnabled,setResetEnabled] = useState(false)
  const [alert,setAlert] = useState<string>()

  const sendCode = async () => {
    const emailsawait = await doResetPassword(props.userName)
    setEmailSent(emailsawait)
    setStep(1)
  }

  const doReset = async ()=>{
    const result = await doConfirmPasswordReset(props.userName,code,password)
    if(result === 'OK')
    {
      setStep(2)
    }
    else {
      setAlert(result)
    }
  }

  const doAlert = (val?:string) => {
    var content;
    if(!val) return undefined;

    if(val === "CODE")
      content = 'Código inválido ou expirado.'
    else {
      content = (<div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        Email não encontrado ou senha não atende aos parâmetros. 
        <Tooltip title={(
          <div>
            A senha deve conter os seguintes elementos:
            <ul>
              <li>Pelo menos 1 dígito</li>
              <li>Pelo menos 1 símbolo</li>
              <li>Pelo menos 1 letra maiúscula</li>
              <li>Pelo menos 1 letra minúscula</li>
            </ul>
          </div>
        )}>
          <InfoCircleFilled style={{marginLeft:'0.5rem'}} />
        </Tooltip>
      </div>)
    }
  
    return (
      <Alert message={content} type="error" closable onClose={()=>setAlert(undefined)}/>
    )
  }
  
  useEffect(()=>{
    setResetEnabled(!(alert === undefined && code.length === 6 && password === confirm && password.length > 0))

  },[code,password,confirm,alert])

  var dialogContent, dialogActions;

  if (step === 0) {
    dialogContent = (
      <Flex vertical>
        <span className="resetMessage" >
          Para o processo de recuperação de senha, um código será enviado para o email associado ao seu usuário.
        </span>
        <span className="resetMessage" >
          Com esse código, você pode prosseguir para a criação de uma nova senha.
        </span>
        <span className="resetMessage resetMessageEmphasis" >
          Deseja continuar?
        </span>
      </Flex>
    )

    dialogActions = (
      <>
        <Button onClick={sendCode}>Sim, enviar o código</Button>
        <Button autoFocus onClick={props?.onClose}>Não, cancelar</Button>
      </>
    )
  } else if (step === 1) {
    dialogContent = (
      <Flex vertical>
        <span className="resetMessage" >
          Um email foi enviado para {emailSent}.
        </span>
        <span className="resetMessage resetMessageEmphasis" >
          Digite o código enviado abaixo.
        </span>
        <div className="codeInput">
          <Input.OTP onChange={setCode} value={code}  />
        </div>
        <div className="codeInput">
          <Input.Password className="rightAreaInput" placeholder = "Nova senha" style={{marginBottom:'1.5rem'}} onChange={(e) => setPassword(e.target.value)} value={password}/>
        </div>
        <div className="codeInput">
          <Input.Password className="rightAreaInput" placeholder = "Confirme nova senha" style={{marginBottom:'1.5rem'}} onChange={(e) => setConfirm(e.target.value)} value={confirm}/>
        </div>
      </Flex>
    )

    dialogActions = (
      <>
        <Button disabled={resetEnabled} onClick={doReset}>Alterar Senha</Button>
        <Button autoFocus onClick={props?.onClose}>cancelar</Button>
      </>
    )
  } else if(step === 2) {
    dialogContent = (
      <Flex vertical>
        <span className="resetMessage" >
          Senha alterada com sucesso.
        </span>
        <span className="resetMessage resetMessageEmphasis" >
          Você deve fechar esta janela e realizar o login com a nova senha.
        </span>
      </Flex>
    )

    dialogActions = (
      <>
        <Button autoFocus onClick={props?.onClose}>Fechar</Button>
      </>
    )
  }

  return (
    <Modal centered open={props.open} title="Recuperação de Senha" width="40%" footer={dialogActions} closable={false}>
         {doAlert(alert)}
         {dialogContent}
    </Modal>
  );
}

