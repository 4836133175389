import React, { ReactNode } from 'react';
import { ConfigProvider } from 'antd';

interface ThemeProviderProps {
    children: ReactNode;
}

const AppThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    // Customize the theme here
    const theme = {
        cssVar: true,
        token: {
            colorPrimaryBg: '#7f7f7f',
            colorPrimaryBorderHover: '#7f7f7f',
            fontFamily: 'Fira Sans',
            journoIconColor: '#B17F4A'

        },
        components: {
            Input: {
                hoverBorderColor: '#7f7f7f',
                activeBorderColor: '#7f7f7f',
            },
            Modal : {
                titleFontSize: 20
            },
            Layout: {
                headerBg: '#f2f2f2',
            },
            Button: {
                defaultBg: '#7f7f7f',
                defaultHoverBg: '#4b41a1',
                defaultColor: 'white',
                defaultHoverColor: 'white',
                colorPrimaryHover: '#4b41a1',
                paddingBlock: '2rem'
            },
            Segmented: {
                itemSelectedBg: '#7f7f7f',
                itemColor: 'white',
                itemSelectedColor: 'white',
                itemHoverColor: 'white',
                trackBg: '#7f7f7fAF',
                itemHoverBg: '#4b41a1',
            },
            Tabs: {
                itemColor: '#4b41a1',
                itemSelectedColor: '#7f7f7f',
                itemHoverColor: '#4b41a1',
                titleFontSize: 14,
                inkBarColor: '#7f7f7f',
            },
            Select: {
                optionSelectedColor: 'white',
                selectorBg: 'transparent',
            }
        }

    };

    return (
        <ConfigProvider theme={theme}>
            {children}
        </ConfigProvider>
    );
};

const LoginThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    // Customize the theme here
    const theme = {
        cssVar: true,
        components: {
            Button: {
                defaultBg: '#B17F4A',
                defaultHoverBg: '#7f7f7f',
                linkHoverColor: '#7f7f7f',
                defaultColor: 'white',
                defaultHoverColor: 'white',
                colorLink: '#B17F4A',
                colorPrimaryHover: '#7f7f7f'
            }
        }

    };

    return (
        <ConfigProvider theme={theme}>
            {children}
        </ConfigProvider>
    );
};

export {AppThemeProvider,LoginThemeProvider};