import { Divider, Flex, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import './questions.css'

export function QuestionComponent(props: {
  questions: string[],
  data?: string[],
  order?: number[],
  optional?: number[],
  handleValuesChange: (newValues: string[]) => void

}) {
  const [questionValues, setQuestionValues] = useState(props.questions.map(() => ''))

  useEffect(() => {
    if(props.data){
      console.log('useEffect',questionValues,props.data)
      setQuestionValues(props.data)
    }
  },[props.data])

  const handleQuestionChange = (e: any,idx:number) => {
    console.log('handleQuestionChange',idx,questionValues)
    questionValues[idx] = e.target.value;
    const newValues = [...questionValues];
    setQuestionValues(newValues);
    console.log('handleQuestionChange1',newValues)
    props.handleValuesChange(newValues)
  }

  const theOrder = props.order || props.questions.map((x,idx) => idx)

  return (
    <Flex vertical className="create-text-section-panel" gap="small">
      {
        theOrder.map((x,idx) => (
          <div key={idx}>
          <Flex vertical className="create-text-section-item" >
            <span>{props.questions[x]}{props.optional && props.optional.includes(x) ? "" : "*"}</span>
            <TextArea rows={2} onChange={(e)=>handleQuestionChange(e,x)} value={questionValues[x]} />
          </Flex>
          {idx < theOrder.length - 1 && (<Divider />)}
          </div>
        ))
      }
    </Flex>

  )
  
}

