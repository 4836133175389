import { Avatar, Button, Divider, Dropdown, Flex, Layout, MenuProps } from 'antd';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { UserOutlined } from '@ant-design/icons';
import ActionButton from './components/ActionButton';
import { Outlet, useNavigate } from 'react-router-dom';
import { doSignOut } from './functions/CognitoClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    }
  }
});



function App() {
  const navigate = useNavigate()
  
  const navigateTo = () => {
      navigate("/app/dashboard")
  }

  const logout = async () => {
    await doSignOut()
    navigate('/')
  }

  const userMenu: MenuProps['items'] = [
    {
      label: <span className='menu-item' onClick={logout}>Logout</span>,
      key: '0'
    }
  ]


  return (
    <QueryClientProvider client={queryClient}>
      <Layout >
        <Header className='header'>
          <Flex justify="space-between" align="center" style={{width:'100%'}}>
            <img className='logo' src="/logo2.png" width="100%" height='100%' />
            <Flex align='center' >
              <ActionButton className="dashboard-button" onClick={navigateTo}>Dashboard</ActionButton>      
              <Dropdown menu={{items: userMenu}} trigger={['click']}>
                <Avatar className="userMenu" size={48} icon={<UserOutlined />}  />
              </Dropdown>
            </Flex>
          </Flex>
        </Header>
        <Content className='content'><Outlet/></Content>
        <Footer className="footer">
           <Divider />
            <span className='footer-text'>© Journo | Inteligência em Conteúdos para Comunicação Interna 2024</span>
        </Footer>
      </Layout>
    </QueryClientProvider>);
}

export default App;
