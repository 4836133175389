const log = {
    info: (...args: any[]) => {
      if (process.env.REACT_APP_ENABLE_LOG === "TRUE") 
        console.log(...args);
    }
  };
  
  // Exemplo de uso:
  log.info("Esta mensagem", "será registrada", "no console", "somente se ENV não for 'PROD'.");
  

export default {
    NEXA_API : process.env.REACT_APP_NEXA_API,
    log
}