import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queries, { TEXT_INFO } from '../../functions/Queries';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Flex, Modal, Spin, Table, TableColumnsType } from "antd";
import { compareAsc, parse } from "date-fns";
import { GetTextType } from "../../functions/TextFunctions";
import './listText.css';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function ConfirmDeleteDialog(props: {
  open: boolean,
  confirmDialog: (val: string | undefined) => void,
  name: string,
  id: string
}) {
  return (
    <Modal title="Confirma Remoção?" open={props.open} 
      footer = {()=>(
        <>
          <Button id="list-modal-button" onClick={() => props.confirmDialog(props.id)}>CONFIRMAR</Button>
          <Button id="list-modal-button" onClick={() => props.confirmDialog(undefined)}>CANCELAR</Button>
        </>
      )}
    > 
      Confirmar remoção do texto {props.name}. O texto não poderá ser acessado novamente.
    </Modal>
  )
}

const PAGE_SIZE=12;

export default function ListText() {
  const { isPending, isError, data, error } = useQuery(queries.queries.ALL_TEXTS)
  const deleteTextMutation = useMutation(queries.mutations.DELETE_TEXT);
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const navigateTo = (params: any) => {
    navigate("/app/createContent", { state: params })
  }

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<{ name: string, id: string } | undefined>(undefined)
  const [isWaiting, setIsWaiting] = useState(false);

  const confirmDelete = (val: string | undefined) => {
    setConfirmDeleteOpen(undefined)
    if (val) {
      setIsWaiting(true)
      deleteTextMutation.mutate({ id: val },
        {
          onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: queries.queries.ALL_TEXTS.queryKey })
            setIsWaiting(false)
          },
          onError: (err) => {
            console.error(err)
            setIsWaiting(false)
          }
        });
    }
  }

  if (isError) return (<h3>Error: {error.message}</h3>)

  const columns: TableColumnsType<TEXT_INFO> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend','ascend'],	
    },
    {
      title: 'Formato',
      dataIndex: 'type',
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['descend','ascend'],
      render: (text) => {
        const type = GetTextType(text);
        return (
        <Flex>
          <div id="list-text-icon">{type.icon}</div>
          <span>{type.shortTitle}</span>
        </Flex>
      )}
    },
    {
      title: 'Modificado',
      dataIndex: 'lastModified',
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
                const dateA = new Date(a.lastModified)
                const dateB = new Date(b.lastModified)
                const compare = compareAsc(dateA, dateB)
                return compare
              },
      sortDirections: ['descend','ascend'],
    },
    {
      title: 'Criado por',
      dataIndex: 'creator',
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend','ascend'],	
      render: (_,el) => {
        return (
        <Flex justify="space-between">
          {el.creator || 'N/A'}
          <Flex>
            <Button id="list-action-button" shape="circle" icon={<EditOutlined />} onClick={() => navigateTo({ id: el.id, type: el.type })}/>
            <Button id="list-action-button" shape="circle" icon={<DeleteOutlined />} onClick={() => setConfirmDeleteOpen({ name: el.name, id: el.id })}/>
          </Flex>
        </Flex>
      )}
    },

  ]

  return (
    <Spin spinning={isWaiting || isPending}>
      <Table className="list-text-table" rowKey="id" columns={columns} dataSource={data} pagination={{pageSize:getPageSize()}} />
      {confirmDeleteOpen && (<ConfirmDeleteDialog open={!!confirmDeleteOpen} confirmDialog={confirmDelete} name={confirmDeleteOpen.name} id={confirmDeleteOpen.id} />)}
    </Spin>

  );
}

function getPageSize() {
  const width = window.screen.width;
  if (width >= 2560) {
    return 12;
  } else if (width >= 1280) {
    return 5;
  }
  return 5; // Default to 5 if resolution is neither HD nor 4K
}